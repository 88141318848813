'use client';

import ReactDOM from 'react-dom';

import { Locale } from '../../core/i18n/Locale';
import { getMetadata } from '../../core/metadata';
import { useSite } from '../../shared/hooks/useSite';

export function PreloadResources({ locale }: { locale: Locale }) {
    const currentSite = useSite(locale);
    const metadata = getMetadata(currentSite);

    metadata.preloadFonts.forEach((font) => {
        ReactDOM.preload(font.path, {
            as: 'font',
            type: font.type,
            crossOrigin: 'anonymous',
        });
    });

    metadata.preconnect.forEach((preconnect) => {
        ReactDOM.preconnect(preconnect.url, {
            ...(preconnect.crossOrigin ? { crossOrigin: 'anonymous' } : {}),
        });
    });

    return null;
}
