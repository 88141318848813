import { pyriteStaticPath, staticCdnUrl } from './Assets/Assets';
import { Site } from './Site/Site';

export function getMetadata(site: Site) {
    return {
        viewport: {
            width: 'device-width',
            initialScale: 1,
        },
        themeColor: '#003526',
        favicon: pyriteStaticPath('varanasi/assets/images/favicon.png'),
        preloadFonts: [
            {
                path: pyriteStaticPath(
                    'varanasi/assets/fonts/OpenSauceOne/open-sauce-one-regular.woff2',
                ),
                type: 'font/woff2',
            },
            {
                path: pyriteStaticPath(
                    'varanasi/assets/fonts/OpenSauceOne/open-sauce-one-bold.woff2',
                ),
                type: 'font/woff2',
            },
            {
                path: pyriteStaticPath(
                    'varanasi/assets/fonts/OpenSauceOne/open-sauce-one-extrabold.woff2',
                ),
                type: 'font/woff2',
            },
            {
                path: pyriteStaticPath('varanasi/assets/fonts/Moret/moret-regular.otf'),
                type: 'font/otf',
            },
            {
                path: pyriteStaticPath('varanasi/assets/fonts/Moret/moret-bold.otf'),
                type: 'font/otf',
            },
        ],
        preconnect: [
            {
                url: 'https://www.googletagmanager.com',
                crossOrigin: true,
            },
            {
                url: 'https://sdk.privacy-center.org',
                crossOrigin: true,
            },
            {
                url: staticCdnUrl(),
                crossOrigin: true,
            },
        ],
        verification: {
            botify: site.siteVerification.botify,
            google: site.siteVerification.google,
        },
        brandedDomains: 'www.pages03.net,evaneos.mkt6459.com',
    };
}
